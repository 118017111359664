import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import BlogItem from '../components/blocks/blog-item/blog-item';
import Container from '../components/container/container';
import PageHeader from '../components/paragraphs/page-header/page-header';
import { graphql } from 'gatsby';

const Blog = props => {
  const { location, data } = props;
  const langCode =
    location.pathname.split('/').filter(e => e !== '')[0] === 'en'
      ? 'en'
      : 'nl';
  const sortedItems =
    data.allNodeArticle.nodes &&
    data.allNodeArticle.nodes[0].created > data.allNodeArticle.nodes[1].created
      ? data.allNodeArticle.nodes
      : data.allNodeArticle.nodes.reverse();

  return (
    <Layout location={location}>
      <SEO title="Blog" lang={langCode} />
      <PageHeader
        title={'Blog'}
        align={'Gecentreerd'}
        colors={'Groen'}
        breadcrumbs={[{ url: '/', title: 'Home' }]}
        langCode={langCode}
      ></PageHeader>
      <Container style={{ maxWidth: 960 }}>
        {data.allNodeArticle.nodes &&
          data.allNodeArticle.nodes.length > 0 &&
          sortedItems.map(item => (
            <BlogItem
              title={item.title}
              date={item.created}
              link={item.path.alias}
              image={
                item.relationships.field_media.field_media_image
                  .imageDerivatives.links.blog_overview.href
              }
            >
              {item.body && item.body.summary && <p>{item.body.summary}</p>}
            </BlogItem>
          ))}
      </Container>
    </Layout>
  );
};
export default Blog;

export const query = graphql`
  query BlogOverview {
    allNodeArticle {
      nodes {
        title
        created
        body {
          summary
          processed
        }
        path {
          alias
        }
        relationships {
          field_media {
            field_media_image {
              imageDerivatives {
                links {
                  blog_overview {
                    href
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
